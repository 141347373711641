<template>
  <div class="flow dgrid-body dgrid-extra-gap">
    
    <h2 class="view-title">{{ $t('eventi_titolo_pagina') }}</h2>

    <template v-if="loading || futureEvents.length || pastEvents.length">
      <template v-if="loading || futureEvents.length">
        <h3>{{ $t('eventi_titolo_sez_eventi_in_programma') }}</h3>
        <div class="dgrid-container dgrid-minw-wrap">

          <template v-if="loading">
            <base-cs-event
              v-for="i in 3"
              :key="i"
              skeleton
            />            
          </template>

          <template v-else>
            <a :href="`events/${event.slug}`">
              <base-cs-event 
                v-for="event in futureEvents" :key="event.id"
                :item="event"
                @clicked="goToEvent(event.slug)"
              />
            </a>
                        
          </template>

        </div>
      </template>

      <div v-if="futureEvents.length && pastEvents.length" class="bordered-bottom" style="--color-border:var(--color-light);"></div>

      <template v-if="loading || pastEvents.length">
        <h3>{{ $t('eventi_titolo_sez_eventi_passati') }}</h3>
        <div class="dgrid-container dgrid-minw-wrap" style="--dgrid-min-width: 23rem;">

          <template v-if="loading">
            <base-cs-event 
              v-for="i in 4" :key="i"
              skeleton
            />            
          </template>

          <template v-else>
            <template v-for="event in pastEventsSorted">
              <a :href="`events/${event.slug}`" :key="event.id" @click.prevent>
                <base-cs-event 
                  :item="event"
                  @clicked="goToEvent(event.slug)"
                /> 
              </a>  
            </template>      
          </template>

        </div>
      </template>      
    </template>

    <template v-else>
      <h4>{{ $t('events_no_events') }}</h4>
    </template>

    <div class="view-bottom-margin"></div>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import langMixin from '@mixins/lang.js';

export default {
  name: 'Events',
  mixins: [ langMixin ],
  computed: {
    ...mapState('cms', ['loading']),
    ...mapGetters('cms', ['futureEvents', 'pastEvents']),
    pastEventsSorted() {
      return this.pastEvents.sort((a,b) => new Date(b.date) - new Date(a.date));
    }
  },
  methods: {
    ...mapActions('cms', ['getList']),
    goToEvent(slug) {
      this.$router.push({name: 'event', params: { slug }})
    },
    langCb() {
      this.getList('events');
    }
  },
  created() {
    this.getList('events');
  }
}
</script>
