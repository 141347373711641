export default {
	computed: {
		lang() {
			return this.$i18n.locale;
		}
	},
	watch: {
		lang() {
			this.langCb();
		}
	},
	methods: {
		langCb() {
			console.log("language changed");
		}
	}
}